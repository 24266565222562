<template>
<div v-if="$parent.user != null" class="checkout" style="padding-top: 50px;">

    <vs-row justify="center" style="margin-top: 40px;" v-if="user != null">

        <vs-row style="width: 69%;" justify="space-between">
            <vs-row v-if="qty > 10" style="margin-bottom: 20px;">
                <vs-alert shadow>
                    <template #title>
                        Ordinazione personalizzata.
                    </template>
                    Selezionando una quantità superiore a 10 unità si verrà contattati dal nostro staff per un preventivo personalizzato, anzichè procedere direttamente al pagamento con carta.
                </vs-alert>
            </vs-row>
            <vs-col style="width: 72%; background: white;padding: 20px;border-radius: 21px;">
                <vs-row>
                    <h2>Dati {{ isBusiness ? 'aziendali' : 'anagrafici' }}</h2>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" style="padding-right: 10px;">
                        <vs-input type="text" label="Nome" v-model="user.name" block />
                    </vs-col>
                    <vs-col w="6" style="padding-left: 10px;">
                        <vs-input type="text" label="Cognome" v-model="user.surname" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 40px;">

                    <vs-col w="6" style="padding-right: 10px;">
                        <vs-input type="text" label="Codice Fiscale" v-model="user.cf" block />
                    </vs-col>
                    <vs-col w="6" style="padding-left: 10px;" v-if="isBusiness">
                        <vs-input type="text" label="PIVA" v-model="user.vat" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 40px;" v-if="isBusiness">
                    <vs-col w="6" style="padding-right: 10px;">
                        <vs-input type="text" label="Ragione sociale" v-model="user.businessName" block />
                    </vs-col>
                    <vs-col w="6" style="padding-left: 10px;">
                        <vs-input type="text" label="Codice univoco" v-model="user.uniqueCode" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 20px;">
                    <h2>Informazioni di contatto</h2>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" style="padding-right: 10px;">
                        <vs-input type="text" label="Cellulare" v-model="user.mobile" block />
                    </vs-col>
                    <vs-col w="6" v-if="isBusiness" style="padding-left: 10px;">
                        <vs-input type="text" label="PEC" v-model="user.pec" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 20px;">
                    <h2>Indirizzo di fatturazione</h2>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="5" style="padding-right: 10px;">
                        <vs-input type="text" label="Indirizzo" v-model="user.address" block />
                    </vs-col>
                    <vs-col w="1" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="No." v-model="user.addressNo" block />
                    </vs-col>
                    <vs-col w="2" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="CAP" v-model="user.postalCode" block />
                    </vs-col>
                    <vs-col w="2" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="Città" v-model="user.city" block />
                    </vs-col>
                    <vs-col w="2" style="padding-right: 10px;">
                        <vs-input type="text" label="Paese" v-model="user.country" block />
                    </vs-col>

                </vs-row>

                <!-- SPEDIZIONE-->

                <vs-row style="padding-top: 20px;">
                    <h2>Indirizzo di spedizione</h2>
                </vs-row>
                <vs-row style="margin-top: 15px;">
                    <vs-checkbox v-model="sameInvoiceAndShipping">
                        Le informazioni di spedizione e di fatturazione combaciano.
                    </vs-checkbox>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" style="padding-right: 10px;">
                        <vs-input type="text" label="Nome" v-model="shippingUser.name" block />
                    </vs-col>
                    <vs-col w="6" style="padding-left: 10px;">
                        <vs-input type="text" label="Cognome" v-model="shippingUser.surname" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="5" style="padding-right: 10px;">
                        <vs-input type="text" label="Indirizzo" v-model="shippingUser.address" block />
                    </vs-col>
                    <vs-col w="1" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="No." v-model="shippingUser.addressNo" block />
                    </vs-col>
                    <vs-col w="2" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="CAP" v-model="shippingUser.postalCode" block />
                    </vs-col>
                    <vs-col w="2" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="Città" v-model="shippingUser.city" block />
                    </vs-col>
                    <vs-col w="2" style="padding-right: 10px;">
                        <vs-input type="text" label="Paese" v-model="shippingUser.country" block />
                    </vs-col>

                </vs-row>
                <vs-row style="margin-top: 15px;">
                    <vs-checkbox v-model="isBusiness">
                        Sei una scuola o un'associazione?
                    </vs-checkbox>
                </vs-row>
                <vs-row style="margin-top: 15px;">
                    <vs-checkbox v-model="saveUser">
                        Salva informazioni di fatturazione come predefinite.
                    </vs-checkbox>
                </vs-row>
            </vs-col>

            <vs-col w="3" style="background: white;padding: 20px;border-radius: 21px">
                <vs-row>
                    <p>1 ELEMENTO</p>
                </vs-row>
                <vs-row justify="space-between" align="center">
                    <vs-col w="3">
                        <img :src="baseUrl + '/Product/Media/' + product.fileId" style="width: 100%;" />
                    </vs-col>
                    <vs-col w="8">
                        <vs-row direction="column">
                            <b>{{ product.title }}</b>
                            <small>{{ pages.length -1 }} pagine</small>
                        </vs-row>
                        <vs-row align="center" justify="center">
                            <vs-button icon circle :disabled="qty <= 1 || digitalVersionOnly" @click="qty--">
                                <i class="bx bx-sm bx-minus"></i>
                            </vs-button>
                            <vs-input v-model="qty" type="number" :disabled="digitalVersionOnly" min="1" max="50"></vs-input>
                            <vs-button icon circle :disabled="qty >= 50 || digitalVersionOnly" @click="qty++">
                                <i class="bx bx-sm bx-plus"></i>
                            </vs-button>
                        </vs-row>
                    </vs-col>
                </vs-row>
                <vs-row justify="space-between">
                    <p>Subtotal</p>
                    <p>{{((digitalVersionOnly ? product.productVariants.find(e => e.pages == (pages.length - 1)).digitalPrice : product.productVariants.find(e => e.pages == (pages.length - 1)).price)*qty).toFixed(2)}}€</p>
                </vs-row>
                <vs-row justify="space-between">
                    <b>Total</b>
                    <p>{{((digitalVersionOnly ? product.productVariants.find(e => e.pages == (pages.length - 1)).digitalPrice : product.productVariants.find(e => e.pages == (pages.length - 1)).price)*qty).toFixed(2)}}€</p>
                </vs-row>
                <vs-row style="margin-top: 15px;">
                    <vs-checkbox v-model="digitalVersionOnly">
                        Solo versione digitale
                    </vs-checkbox>
                    <small>Con l'acquisto della copia fisica, si avrà a disposizione anche la copia digitale.</small>
                </vs-row>
                <vs-row>
                    <vs-button size="large" block icon @click="createOrder" >
                        <template v-if="qty <= 10">
                            <i class='bx bxs-credit-card bx-sm'></i> Procedi al pagamento
                        </template>
                        <template v-else>
                            <i class='bx bx-cart-alt bx-sm'></i> Invia il tuo ordine
                        </template>
                    </vs-button>
                </vs-row>
            </vs-col>

        </vs-row>

    </vs-row>
</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import {
    apiCall,
    baseUrl
} from '../client';
export default {
    props: {
        pages: Object,
        product: Object
    },
    components: {},
    data() {
        return {
            user: null,
            shippingUser: {
                name: '',
                surname: '',
                address: '',
                addressNo: '',
                postalCode: '',
                city: '',
                country: ''
            },
            baseUrl,
            saveUser: false,
            isBusiness: false,
            digitalVersionOnly: false,
            qty: 1,

            sameInvoiceAndShipping: false
        }
    },
    mounted() {
        if (this.pages == null || this.product == null) {
            this.$router.push({
                name: 'feed'
            }); // tentativo di accesso diretto
        }
        this.user = JSON.parse(JSON.stringify(this.$parent.user.invoicingDetails));
    },

    watch: {
        sameInvoiceAndShipping(n) {
            if (n) {
                this.shippingUser = JSON.parse(JSON.stringify(this.user));
            } else {
                this.shippingUser = {
                    name: '',
                    surname: '',
                    address: '',
                    addressNo: '',
                    postalCode: '',
                    city: '',
                    country: ''
                };
            }
        },
        '$parent.user'(n) {
            this.user = JSON.parse(JSON.stringify(n.invoicingDetails));
        },

        isBusiness(n) {
            if (!n) {
                this.user.vat = null;
                this.user.pec = null;
            }
        },

        digitalVersionOnly(n) {
            if (n) {
                this.qty = 1;
            }
        }
    },

    methods: {
        async putOrder(loading) {
            var vm = this;
            // creo l'ordine
            this.pages = this.pages.filter(e => e.layoutId != undefined); // rimuovo quelle vuote che servono solo al componente della preview
            for (var i in this.pages) {
                var p = this.pages[i];
                for (var k in p.elements) {
                    if (p.elements[k].value != undefined) {
                        p.elements[k].value.croppedImage = undefined;
                    }
                }
            }

            const result = await apiCall('PUT', '/Order', {
                pages: this.pages,
                invoicedUser: this.user,
                shippedUser: this.shippingUser,
                digitalVersionOnly: this.digitalVersionOnly,
                qty: this.qty,
            });
            
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Ordine creato con successo.`
                })

                if (result.data.invoices[0].stripeInvoiceUrl == null) {
                    loading.close();
                    vm.$router.push({
                        name: 'afterCheckout',
                        params: {
                            invoice: result.data.invoices[0]
                        }
                    });
                    return;
                }
                const windowProxy = window.open(result.data.invoices[0].stripeInvoiceUrl, 'targetWindow',
                    `toolbar=no,
                                                                                        location=no,
                                                                                        status=no,
                                                                                        menubar=no,
                                                                                        scrollbars=yes,
                                                                                        resizable=yes,
                                                                                        width=800,
                                                                                        height=640`);

                if (!windowProxy) {
                    throw new Error(`Could not get window proxy for URL: "${result.data.invoices[0].stripeInvoiceUrl}"`);
                }

                loading.changeText('Continua su Stripe...');
                var timer = setInterval(function () {
                    if (windowProxy.closed) {
                        clearInterval(timer);
                        loading.close();
                        vm.$router.push({
                            name: 'afterCheckout',
                            params: {
                                invoice: result.data.invoices[0]
                            }
                        });
                    }
                }, 500);

            } else {
                loading.close();
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile creare l'ordine.`
                })
            }

            
        },

        async createOrder() {
            // validate fields
            var mandatoryFields = {
                name: 'nome',
                surname: 'cognome',
                address: 'indirizzo',
                addressNo: 'indirizzo n°',
                postalCode: 'CAP',
                city: 'città'
            };
            for (var f in mandatoryFields) {
                if (this.user[f] == null || this.user[f].length < 1) {
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Il campo ${mandatoryFields[f]} è obbligatorio.`
                    })
                    return;
                }
            }

            for (f in mandatoryFields) {
                if (this.shippingUser[f] == null || this.shippingUser[f].length < 1) {
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Il campo ${mandatoryFields[f]} è obbligatorio.`
                    })
                    return;
                }
            }

            // business mandatory fields
            if (this.isBusiness) {
                var bMandatoryFields = {
                    vat: 'PIVA',
                    pec: 'PEC'
                };
                for (f in bMandatoryFields) {
                    if (this.user[f] == null || this.user[f].length < 1) {
                        this.$vs.notification({
                            icon: '<i class="bx bx-error"></i>',
                            color: 'danger',
                            position: 'top-right',
                            title: 'Ops!',
                            text: `Il campo ${bMandatoryFields[f]} è obbligatorio per le aziende.`
                        })
                        return;
                    }
                }
            }

            const loading = this.$vs.loading({
                text: 'Creazione ordine...'
            });
            await this.putOrder(loading);

        },
    }
}
</script>
